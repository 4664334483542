<template>
  <div class="detailsPage">
    <div class="banner">
      <div style="width: 1136px;margin: 0 auto;text-align: left;">
        <van-icon name="arrow-left" @click="goBack"/>
        <div class="aboutThis">{{ title }}</div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="params.advertorial3 != 3">
          <van-swipe-item>
            <!-- <div v-if="params.banner"> -->
            <div v-if="params.advertorial3 == 1">
              <img :src="params.banner" alt="" srcset="" class="img">
              <div class="detailRichText ql-editor" v-html="content"></div>
            </div>
            <div v-else>

            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="video-div" style="width: 1136px;margin: 0 auto;text-align: center;" v-if="params.advertorial3 == 2 || params.advertorial3 == 3">
        <el-card  shadow="always" style="width: 1136px; margin-left: 2%; border-radius: 3px; height: 100%;">
          <VideoPlay class="video-player vjs-custom-skin" :videoCover="getVideoCover(params.banner)" :videoUrl="params.banner" :autoplay="false" :loop="false"
                     :muted="true" :type="'block'"/>
          <div class="aboutThis" style="float:left;margin:5px auto 0">{{ params.title }}
          </div>
          <div class="detailRichText" style="float:left;;margin:5px auto 0" v-html="content"></div>
        </el-card>
        <el-card shadow="always" style="width: 1136px; margin-left: 2%; border-radius: 3px; height: 70px;margin-top:15px;">
          <el-col :span="12" style="text-align: left;color:rgb(191, 191, 191);font-size: 0.2rem;">
            <div class="viewa"> {{ params.reviews || 0 }} {{ $t("app.reviews") }}</div>
          </el-col>
          <!-- <el-col :span="12" style="text-align: right;">
          <div>
            <a href="" style="margin-right: 20px;">
              <svg t="1687765561872" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3843" width="25" height="25" style="padding-bottom: 6px;">
                <path d="M1024 512c0 282.769408-229.230592 512-512 512S0 794.769408 0 512 229.230592 0 512 0v56.889344C260.64896 56.889344 56.889344 260.64896 56.889344 512S260.64896 967.110656 512 967.110656 967.110656 763.35104 967.110656 512H1024z" fill="#bfbfbf" p-id="3844"></path>
                <path d="M968.547328 45.056L365.568 661.520384 403.612672 700.416 1006.592 83.951616z" fill="#bfbfbf" p-id="3845"></path>
                <path d="M698.368 29.696h323.584v55.296H698.368z" fill="#bfbfbf" p-id="3846"></path>
                <path d="M969.728 29.696h52.224v328.704h-52.224z" fill="#bfbfbf" p-id="3847"></path>
              </svg>
            </a>
            <a href="">
              <svg t="1687765462131" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2673" width="35" height="35">
                <path d="M908.1 353.1l-253.9-36.9L540.7 86.1c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L369.8 316.2l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3-12.3 12.7-12.1 32.9 0.6 45.3l183.7 179.1-43.4 252.9c-1.2 6.9-0.1 14.1 3.2 20.3 8.2 15.6 27.6 21.7 43.2 13.4L512 754l227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM664.8 561.6l36.1 210.3L512 672.7 323.1 772l36.1-210.3-152.8-149L417.6 382 512 190.7 606.4 382l211.2 30.7-152.8 148.9z" p-id="2674" fill="#bfbfbf"></path>
              </svg>
            </a>
          </div>
          </el-col> -->

        </el-card>
      </div>
    </div>



    <details-part0 :scenicList="districtList"></details-part0>
    <details-Part2 :scenicList="districtList"></details-Part2>
    <details-part1-pic :adv="adv"></details-part1-pic>
    <details-part3 :scenicList="districtList"></details-part3>
  </div>
</template>
<script>
import {Swipe, SwipeItem} from 'vant';
import NavBar from "../../components/basicComponents/navBar";
import CategoryBar from "../../components/basicComponents/categoryBar";
import apis from "../../utils/apis";
import AdSpace from "../../components/adSpace";
import SearchBtn from "../../components/basicComponents/searchBtn";
import HomeBanner from "../../components/homeBanner";
import DetailsPart0 from "../../components/detailsComponents/detailsPart0";
import DetailsPart1Pic from "../../components/detailsComponents/detailsPart1Pic";
import DetailsPart2 from "../../components/detailsComponents/detailsPart2";
import DetailsPart3 from "../../components/detailsComponents/detailsPart3";
import DetailsPart1 from "../../components/detailsComponents/detailsPart1.vue"
import shop_time from "../../assets/img/shop_time.png"
import shop_tel from "../../assets/img/shop_tel.png"
import shop_mail from "../../assets/img/shop_mail.png"
import shop_home from "../../assets/img/shop_home.png"
import shop_loc from "../../assets/img/shop_loc.png"
import VideoPlay from "../../components/video/ViedoPlayer.vue"

let {articleDetail,indexRecommend, addClick} = apis
export default {
  components: {
    DetailsPart1Pic,
    DetailsPart3,
    DetailsPart2,
    DetailsPart0,
    DetailsPart1,
    HomeBanner,
    SearchBtn,
    AdSpace,
    CategoryBar,
    NavBar,
    VideoPlay,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      type: '',
      title:"",
      content: '',
      params:"",
      shopName: 'Tokyo Disneyland',
      params1:{},
      shop_time: shop_time,
      shop_tel: shop_tel,
      shop_mail: shop_mail,
      shop_home: shop_home,
      shop_loc: shop_loc,
      adv:[{
        img:'https://jpinbound.s3.ap-northeast-1.amazonaws.com/image/banner/0e64b3264fca4cfe97c79af50c311a54.JPG',
        name:'Tokyo Tower'
      }],
      districtList: [],
      scenicList: [],
      shopList: [],
      ticketList: [],
      travelStoryList: [],
      viewa:"",
    };
  },
  watch: {
    $route(to, from) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
    },
  },
  mounted(){
    this.type = this.$route.query.type
    this.getDate(this.$route.query.id)
    this.initData();
    this.randomNumber()
  },
  created() {
    this.click();
  },
  methods: {
    click() {
      let params = {
        foreignId: this.$route.query.id,
        clickType: '0',
        domainUrl: window.location.host,
        clickUserId: localStorage.getItem('userId'),
      };
      addClick(params);
    },
    initData() {
      //1-地区 2-景点 4-攻略 5-店铺 6-票 0-全部
      // this.getRecommendList('1')
      // this.getRecommendList('2')
      // this.getRecommendList('4')
      this.getRecommendList('5')
      // this.getRecommendList('6')
    },
    getDate(articleId) {
      let params = {
        articleId: articleId
      }
      const _this = this
      articleDetail({...params}, data => {
        if (data.data.title) {
          this.params=data.data
          this.districtList=data.data
          this.title= data.data.title
          if(data.data.content) {
            this.content = data.data.content.replaceAll('<p>&nbsp;</p>',  '');
            this.content = this.content.replaceAll('&nbsp;',  '');
            this.content = this.content.replaceAll('<p><br></p>',  '');
            this.content = this.content.replaceAll('<p><strong></strong></p>',  '');
          }
          let removeFlag = -1
          if(_this.districtList.extra && _this.districtList.extra.length > 0){
            for(let i = 0 ;i < _this.districtList.extra.length; i++ ){
              if(_this.districtList.extra[i].title == '__mediabank_adv__'){
                _this.adv = _this.districtList.extra[i]
                removeFlag = i
                break
              }
            }
            if(removeFlag>-1){
              _this.$delete(_this.districtList.extra,removeFlag)
            }
          }
        } else {
          this.$router.replace('/homePage');
        }
      });
    },
    getRecommendList(type) {
      let params = {
        type: type,
      }
      indexRecommend({...params}, data => {
        if (type == '1') {
          this.districtList = data.data
        } else if (type == '5') {
          this.shopList = data.data
        } else if (type == '2') {
          this.scenicList = data.data
        } else if (type == '6') {
          this.ticketList = data.data
        } else if (type == '4') {
          this.travelStoryList = data.data
        }
        // console.log(2222,this.districtList)
      });
    },
    goSearch(name, index) {
      this.$router.push({
        path: '/result',
        query: {
          name: name,
          index: index
        }
      })
      // console.log(name, index)
    },
    toSearchClassify() {
      this.$router.push({
        path: '/searchClassify',
        query: {
          title: this.title,
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    getVideoCover(videourl){
      // var reg1 = new RegExp(".mp4video","g");
      // var reg2 = new RegExp(".mp4","g");
      // let result = videourl.replace(reg2,'');
      // result = videourl.replace(reg1,'');
      let result = videourl?.substring(0,videourl.lastIndexOf('.'));
      return result+"_cover.jpeg"
    },
    randomNumber() {
      let shareArr = [
        "1000+",
        "2000+",
        "3000+",
        "4000+",
        "5000+",
        "6000+",
        "7000+",
        "8000+",
        "9000+",
      ];
      var str = ""; //重新调用赋值给空
      var rond = Math.round(Math.random());
      str += shareArr[rond];
      this.viewa = str;
    },
  },


};
</script>

<style lang="less">
.detailsPage {
  background: white;
  margin-bottom: 80px;

  .banner {
    margin-top: 160px;
    .my-swipe {
      width: 1136px;
      margin: 0 auto;
    }
    img {
      height: 369px;
      display: block;
    }
    img.u-play {
      height: 86px;
    }
    .van-icon {
      cursor: pointer;
    }
  }

  .areaContent {
    font-size: .18rem;
    text-decoration: underline;
    text-align: left;
    width: 1136px;
    margin: .3rem auto 0;
    cursor: pointer;
  }

  .aboutThis {
    color: black;
    font-size: 0.3rem;
    font-weight: 600;
    margin: 0px 0 30px 10px;
    display: inline-block;
  }

  .detailRichText {
    text-align: start;
    width: 1136px;
    margin: .24rem auto;
    p{
      font-size: 0.2rem;
      color: #727171;
      img{
        margin: .2rem 0;
        width: 100%;
        object-fit: cover;
        display: block;
        height: 500px;
      }
      span{
        display: block;
        margin-bottom: .24rem;
      }
      strong {
        display: inline-block;
        width: 100%;
      }
    }
  }

  .shopName {
    font-size: 0.5rem;
    font-weight: 700;
    text-align: left;
    margin: 0.4rem 0 0.34rem 0.4rem;
  }

  .numberDiv {
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    font-size: 0.26rem;

    .eMail {
      width: 0.31rem;
      height: 0.31rem;
    }

    .number {
      color: #727171;
      margin-left: 0.2rem;
      text-align: left;
    }
  }
}
.img{
  object-fit: cover;
  width: 100%;
}
.my-swipe {
  cursor: initial !important;
}

.video-player{
  width: 100% !important;
  height:100% !important;
}

.m-video .c_video {
  height: 548px !important;
}
</style>
